@font-face{
    font-family: 'Poppins';
    src: local('Poppins'),url(../fonts/Poppins-Regular.ttf) ;
}

@font-face{
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro'),url(../fonts/SourceSansPro-Regular.ttf);
}
*{
    font-family: "poppins";
}
th{
    /* border-bottom: .07mm solid black;
    border-top: .07mm solid black; */
    padding: .2mm;
}

td{
    padding: .2mm;
}

#totalrow td{
    /* border-bottom: .07mm solid black;
    border-top: .07mm solid black; */
    font-weight: bold;
   
    padding:.2mm;
   
}