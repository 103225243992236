@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@font-face {
    font-family: "FredokaOne";
    src: url(/static/media/FredokaOne-Regular.3888a8fd.ttf);
}
div.newHeaderDark {
    font-family: poppins;
    font-weight: 400;
    font-size: 20px;
    color: rgba(0,0,0,0.75);
  }
div.newSubHeaderDark {
    font-family: poppins;
    font-weight: 100;
    font-size: 14px;
    color: rgba(0,0,0,0.75);
  }
div.newSubHeaderLight {
    font-family: poppins;
    font-weight: 100;
    font-size: 14px;
    color: rgba(0,0,0,0.60);
  }
div.newContent {
    font-family: poppins;
    font-weight: 100;
    font-size: 12px;
    color: rgba(0,0,d0,0.60);
  }
div.newActionablesDark {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: rgba(0,0,0,0.75);
}
div.newActionablesLight {
  font-family: sans-serif;
  font-weight: 100;
  font-size: 20px;
  color: rgba(0,0,0,0.60);
}
@font-face{
    font-family: 'Poppins';
    src: local('Poppins'),url(/static/media/Poppins-Regular.8081832f.ttf);
}

@font-face{
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro'),url(/static/media/SourceSansPro-Regular.efa76f83.ttf);
}
*{
    font-family: "poppins";
}
th{
    /* border-bottom: .07mm solid black;
    border-top: .07mm solid black; */
    padding: .2mm;
}

td{
    padding: .2mm;
}

#totalrow td{
    /* border-bottom: .07mm solid black;
    border-top: .07mm solid black; */
    font-weight: bold;
   
    padding:.2mm;
   
}
.cookiesContent {
  /* width: 320px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 30px 30px 70px;
}
.cookiesContent button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}
.cookiesContent .iconClass {
  /* width: 82px;
  margin-bottom: 15px; */
  font-size: xxx-large;
  color:tomato;
}
.cookiesContent p {
  margin-bottom: 40px;
  font-size: 18px;
}
.cookiesContent button.accept {
  background-color: #ffde17;
  border: none;
  border-radius: 5px;
  width: 200px;
  padding: 14px;
  font-size: 16px;
  color: #0e0620;
  box-shadow: 0px 6px 18px -5px #ffde17;
}

/* :values {
        --blue: #0e0620;
        --white: #fff;
        --green: #2ccf6d;
      } */

.container404 {
 display: flex;
 justify-content: center;
 margin-top: 5%;
}
.btn404 {
  font-family: "Nunito Sans";
}

.ul404 {
  list-style-type: none;
  -webkit-padding-start: 35px;
  padding-inline-start: 35px;
}

.h1404 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight: bold;
  font-family: monospace,'Nunito Sans',sans-serif;
}

.h2404 {
  font-weight: bold;
  font-family: 'Nunito Sans',sans-serif;
}

.btn404 {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
}
.btn404.green {
  border: 4px solid #2ccf6d;
  color: #0e0620;
}
.btn404.green:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: #2ccf6d;
  z-index: -1;
  transition: 0.2s ease;
}
.btn404.green:hover {
  color: #fff;
  background: #2ccf6d;
  transition: 0.2s ease;
}
.btn404.green:hover:before {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .container404 {
    margin-top: 70px;
    margin-bottom: 70px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.emoji {
  box-sizing: border-box;
  float: left;
}
.face {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 15px 30px 0;
  border-radius: 50%;
  background: #e3e3e0;
}

.face:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -8px;
  left: -8px;
  border-radius: 50%;
  box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.07);
}
.eye{
  position: absolute;
  width: 11px;
  height: 11px;
  top: 32px;
  left: 16px;
  z-index: 1;
  border-radius: 50%;
  background: #a7a69e;
}

.eye:last-child {
  left: auto;
  right: 16px;
}

.emoji:hover .eye {
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
@-webkit-keyframes blink {
  10% {
    height: 11px;
    top: 32px;
  }
  30% {
    height: 1px;
    top: 37px;
  }
  50% {
    height: 11px;
    top: 32px;
  }
}
@keyframes blink {
  10% {
    height: 11px;
    top: 32px;
  }
  30% {
    height: 1px;
    top: 37px;
  }
  50% {
    height: 11px;
    top: 32px;
  }
}
.mouth {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  z-index: 1;
  width: 70px;
  height: 34px;
  margin: 0 auto;
  border-radius: 0 0 70px 70px;
  overflow: hidden;
  background: #979795;
}

.mouth:before,
.mouth:after {
  content: '';
  position: absolute;
  display: block;
}
.mouth.tounge:after {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 0;
  right: 0;
  top: 15px;
  margin: 0 auto;
  border-radius: 50%;
  background: #c5c5c3;
}
.sad .mouth {
  top: 44px;
  border-radius: 70px 70px 0 0;
}

.sad .mouth:before {
  display: none;
}

